<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div>
      <b-card :title="$t('Lịch sử rút tiền')">
        <b-row class="mb-1">
          <b-col>
            <b-form-input
              v-model="searchKeyWord"
              :placeholder="$t('Mã giao dịch/nguồn tiền')"
            />
          </b-col>
          <b-col>
            <b-form-datepicker
              v-model="dateFrom"
              local="vn"
              placeholder="Từ ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeFrom"
              :disabled="dateFrom === null"
              locale="en"
              placeholder="From"
            />
          </b-col>

          <b-col>
            <b-form-datepicker
              v-model="dateTo"
              local="vn"
              placeholder="Đến ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeTo"
              :disabled="dateTo === null"
              locale="en"
              placeholder="To"
            />
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              @click="fetchWithdrawalList"
            >
              {{ $t('Tìm kiếm') }}
            </b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            :busy.sync="isBusy"
            responsive
            show-empty
            bordered
          >
            <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>
            <template #empty>
              <b-row>
                <b-col>
                  <b-alert
                    show
                    variant="info"
                    class="text-center"
                  >
                    {{ $t('Không có dữ liệu') }}
                  </b-alert>
                </b-col>
              </b-row>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(bank_master.code)="data">
              <span class="text-nowrap">{{ data.value ? data.value : data.item.to }}</span>
            </template>
            <template #cell(created_at)="data">
              <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
            </template>
            <template #cell(updated_at)="data">
              <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
            </template>
          </b-table>
        </b-card-text>

        <b-row>
          <b-col class="d-flex justify-content-md-end">
            <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              :first-text="$t('Trang đầu')"
              :prev-text="$t('Trang trước')"
              :next-text="$t('Trang tiếp')"
              :last-text="$t('Trang cuối')"
              @change="changePage"
            />
          </b-col>
        </b-row>

      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCard,
  BCardText,
  BTable,
  BAlert,
  BPagination,
  BOverlay,
  BFormDatepicker,
  BFormTimepicker,
  BSpinner,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import {
  required, moneyPayout, integer, moneyPayment,
} from '@validations'
import { formatDateTimeDb } from '@/libs/timezone'
// eslint-disable-next-line import/no-cycle
import { useI18n } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import dateFormat from 'dateformat'
// eslint-disable-next-line import/no-cycle
import { useCreateWithdrawal } from './useOrder'

export default {
  name: 'Wallet',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BTable,
    BAlert,
    BPagination,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BSpinner,
  },
  setup() {
    const { t } = useI18n()
    const showOverlay = ref(false)
    const searchKeyWord = ref(null)
    const dateFrom = ref(null)
    const dateTo = ref(null)
    const timeFrom = ref(null)
    const timeTo = ref(null)

    const {
      fetchWithdrawal,
    } = useCreateWithdrawal()

    const fields = ref([
      {
        key: 'id',
        label: t('mã giao dịch'),
      },
      {
        key: 'bank.bank_number',
        label: t('địa chỉ ví'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'bank.bank_account',
        label: t('mạng lưới'),
      },
      {
        key: 'amount',
        label: t('số tiền rút'),
        thClass: 'text-right',
        tdClass: 'text-right',
        formatter: value => value.toLocaleString(),
      },
      {
        key: 'usdt_fee',
        label: t('phí giao dịch'),
        thClass: 'text-right',
        tdClass: 'text-right',
        formatter: value => value.toLocaleString(),
      },
      {
        key: 'usdt_receive',
        label: t('thực nhận'),
        thClass: 'text-right',
        tdClass: 'text-right',
        formatter: value => value.toLocaleString(),
      },
      { key: 'created_at', label: t('thời gian tạo') },
      { key: 'updated_at', label: t('thời gian cập nhật') },
      {
        key: 'status',
        label: t('trạng thái'),
        thClass: 'text-center',
        tdClass: 'text-center',
        class: 'text-nowrap',
        formatter: value => t(value),
      },
    ])
    const items = ref([])
    const isBusy = ref(false)
    const pagination = ref({
      total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
    })

    const parseDateTime = (date, time, type) => {
      let timeParse
      let second = 0
      if (!date) return null
      if (type === 'from') {
        timeParse = time || '00:00'
      } else {
        timeParse = time || '23:59'
        second = 59
      }
      const dateArr = date.split('-')
      const timeArr = timeParse.split(':')
      return dateFormat(new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], second), 'yyyy-mm-dd HH:MM:ss')
    }

    const fetchWithdrawalList = async () => {
      isBusy.value = true
      const response = await fetchWithdrawal({
        page: pagination.value.current_page,
        search: searchKeyWord.value,
        dateFrom: parseDateTime(dateFrom.value, timeFrom.value, 'from'),
        dateTo: parseDateTime(dateTo.value, timeTo.value, 'to'),
        type: 'usdt',
      })
      if (response) {
        items.value = response.data
        pagination.value = response.meta
      }
      isBusy.value = false
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchWithdrawalList()
    }

    onMounted(() => {
      fetchWithdrawalList()
    })

    return {
      showOverlay,
      searchKeyWord,
      fetchWithdrawalList,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,

      // table
      fields,
      items,
      isBusy,

      // pagination
      pagination,
      changePage,

      // rules
      required,
      moneyPayout,
      moneyPayment,

      formatDateTimeDb,
      integer,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
