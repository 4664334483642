import { ref } from '@vue/composition-api'
import store from '@/store'
import { useI18n } from '@/@core/utils/utils'

export const useOrderPayInUi = () => {
  // const { t } = useI18n()
  const itemView = ref('pay-in')
  const itemViewOptions = ref([
    { icon: 'EyeIcon', value: 'pay-in', text: 'Payin' },
    { icon: 'EyeOffIcon', value: 'pay-out', text: 'Payout' },
  ])

  return {
    itemView,
    itemViewOptions,
  }
}

export const useOrderPayOutUi = () => {
  const itemView = ref('pay-out')
  const itemViewOptions = ref([
    { icon: 'EyeOffIcon', value: 'pay-in', text: 'Pay In' },
    { icon: 'EyeIcon', value: 'pay-out', text: 'Pay Out' },
  ])

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useOderPayInFilter = () => {
  const { t } = useI18n()
  // table
  const pageLength = ref(10)
  const fields = ref([
    '#',
    { key: 'transactionId', label: t('mã giao dịch') },
    {
      key: 'payGateName',
      label: t('kênh'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'receiver',
      label: t('nơi nhận'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'detail.content', label: t('nội dung'), thClass: 'text-center', tdClass: 'text-center',
    },
    {
      key: 'detail.inputAmount',
      label: t('số tiền nhập'),
      thClass: 'text-right',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    {
      key: 'detail.realAmount',
      label: t('số tiền nhận'),
      thClass: 'text-right',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    {
      key: 'detail.forControl',
      label: t('đối soát'),
      thClass: 'text-right',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    {
      key: 'fee',
      label: t('phí'),
      thClass: 'text-right',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    {
      key: 'detail.realReceiveAmount',
      label: t('thực nhận'),
      thClass: 'text-right',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    { key: 'createdAt', label: t('ngày nhận') },
    { key: 'updatedAt', label: t('ngày xử lý') },
    {
      key: 'status',
      label: t('tình trạng'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })
  const searchKeyWord = ref('')

  const handleSearch = (...args) => store.dispatch('order-management/fetchOrder', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'pending':
        return t('Chờ xử lý')
      case 'success':
        return t('Thành công')
      case 'fail':
        return t('Thất bại')
      case 'wrong_amount':
        return t('sai mệnh giá')
      default:
        return t('Chờ xử lý')
    }
  }
  const mapVariant = status => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'fail':
        return 'danger'
      case 'wrong_amount':
        return 'warning'
      default:
        return 'warning'
    }
  }
  const mapPort = port => {
    switch (port) {
      case 'payin':
        return t('QR Payment')
      case 'card':
        return t('Card Payment')
      case 'payout':
        return '_'
      default:
        return '_'
    }
  }

  return {
    t,
    pageLength,
    fields,
    items,
    pagination,
    searchKeyWord,
    mapPort,

    handleSearch,
    mapStatus,
    mapVariant,
  }
}

export const useMasterFilter = () => {
  const { t } = useI18n()
  const merchantName = ref('')
  const merchantNameOptions = ref([])
  const dateFrom = ref('')
  const dateTo = ref('')
  const payGate = ref('')
  const payGateOptions = ref([
    { value: 1, text: t('QR Payment') },
    { value: 2, text: t('Card Payment') },
  ])
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'pending', text: t('Đang xử lý') },
    { value: 'success', text: t('Thành công') },
    { value: 'fail', text: t('Thất bại') },
    { value: 'wrong_amount', text: t('Sai mệnh giá') },
  ])
  const orderStatusPayoutOptions = ref([
    { value: 'pending', text: t('Đang xử lý') },
    { value: 'success', text: t('Thành công') },
    { value: 'fail', text: t('Thất bại') },
  ])
  const statistic = ref({
    totalMoneyTrade: 0, totalMoneyTradeSuccess: 0, totalMoneyTradeFail: 0, totalCountTrade: 0,
  })
  const fetchStatistic = async (...args) => store.dispatch('order-management/fetchStatistic', ...args)

  const fetchWithdrawStatistic = async (...args) => store.dispatch('order-management/fetchWithdrawStatistic', ...args)

  const fetchMerchantNameOptions = async () => {
    merchantNameOptions.value = await store.dispatch('order-management/fetchMerchantParentMaster')
  }

  const fetchPayGateOptions = async () => {
    payGateOptions.value = (await store.dispatch('order-management/fetchPayGateMaster'))
      .filter(item => item.gate === 'payin' || item.gate === 'card')
      .map(gate => ({ value: gate.id, text: t(gate.name) }))
  }

  return {
    merchantName,
    merchantNameOptions,
    dateFrom,
    dateTo,
    payGate,
    payGateOptions,
    orderStatus,
    orderStatusOptions,
    orderStatusPayoutOptions,
    statistic,

    fetchMerchantNameOptions,
    fetchPayGateOptions,
    fetchStatistic,
    fetchWithdrawStatistic,
  }
}

export const useOderPayOutFilter = () => {
  const { t } = useI18n()
  // table
  const fields = ref([
    '#',
    { key: 'transactionId', stickyColumn: true, label: t('mã giao dịch') },
    {
      key: 'receiver',
      label: t('ngân hàng nhận'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'detail.receiverNumber', label: t('tài khoản nhận') },
    { key: 'detail.receiverName', label: t('tên người nhận') },
    {
      key: 'detail.amount',
      label: t('số tiền'),
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => value.toLocaleString(),
    },
    {
      key: 'fee',
      label: t('phí'),
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => value.toLocaleString(),
    },
    {
      key: 'amountChange',
      label: t('tổng số tiền'),
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => value.toLocaleString(),
    },
    {
      key: 'createdAt',
      label: t('ngày nhận'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'updatedAt',
      label: t('ngày xử lý'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'status',
      label: t('tình trạng'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })
  const searchKeyWord = ref('')

  const fetchPayouts = (...args) => store.dispatch('order-management/fetchPayout', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'pending':
        return t('Chờ xử lý')
      case 'success':
        return t('Thành công')
      case 'fail':
        return t('Thất bại')
      case 'wrong_amount':
        return t('Sai mệnh giá')
      default:
        return t('Chờ xử lý')
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'fail':
        return 'danger'
      case 'wrong_amount':
        return 'warning'
      default:
        return 'warning'
    }
  }

  return {
    fields,
    items,
    pagination,
    searchKeyWord,

    fetchPayouts,
    mapStatus,
    mapVariant,
  }
}

export const useTransactionHistory = () => {
  const { t } = useI18n()
  const search = ref('')
  const fields = ref([
    '#',
    { key: 'transactionId', stickyColumn: true, label: t('mã giao dịch') },
    {
      key: 'type',
      label: t('cổng'),
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'port',
      label: t('loại'),
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => t(value),
    },
    { key: 'amountBeforeChange', label: t('số dư đầu') },
    { key: 'amountChange', label: t('biến động') },
    { key: 'amountAfterChange', label: t('số dư cuối') },
    { key: 'updatedAt', label: t('ngày cập nhật') },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })

  const fetchBalanceHistory = (...args) => store.dispatch('order-management/fetchBalanceHistory', ...args)

  return {
    fields,
    items,
    pagination,
    search,
    fetchBalanceHistory,
  }
}

export const useTransactionHistoryUsdt = () => {
  const { t } = useI18n()
  const search = ref('')
  const fields = ref([
    '#',
    { key: 'transactionId', stickyColumn: true, label: t('mã giao dịch') },
    {
      key: 'port',
      label: t('loại'),
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => t(value),
    },
    { key: 'amountBeforeChange', label: t('số dư đầu') },
    { key: 'amountChange', label: t('biến động') },
    { key: 'amountAfterChange', label: t('số dư cuối') },
    { key: 'updatedAt', label: t('ngày cập nhật') },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })

  const fetchBalanceHistory = (...args) => store.dispatch('order-management/fetchBalanceHistory', ...args)

  return {
    fields,
    items,
    pagination,
    search,
    fetchBalanceHistory,
  }
}

export const useCreateWithdrawal = () => {
  const createWithdrawal = (...args) => store.dispatch('order-management/createWithdrawal', ...args)
  const fetchWithdrawal = (...args) => store.dispatch('order-management/fetchWithdrawal', ...args)
  const fetchBankAvailable = (...args) => store.dispatch('order-management/fetchBankAvailable', ...args)

  return {
    createWithdrawal,
    fetchWithdrawal,
    fetchBankAvailable,
  }
}
